















import Vue from 'vue';
import { store } from '@/store';

export default Vue.extend({
  name: 'Teams',
  data() {
    return {
      activeTimelog: false
    };
  },
  computed: {
    isColumnPage(): boolean {
      const routeName = 'Board-Columns';
      return this.$route.matched.some(r => r.name === routeName);
    }
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('auth/fetchMe');
      next();
    } catch (error) {
      next({ name: 'Landing' });
    }
  },

  methods: {}
});
